html {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: 'Raleway', sans-serif;
  font-size: 1em;
  color: #666;
  scroll-behavior: smooth;
}
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
  text-align: left;
  background-color: #000508;
  font-family: 'Raleway', sans-serif;
}
#root {
  height: 100%;
}
header {
  overflow: hidden;
  background-color: #f0eedb;
}
nav {
  background-color: #2e363a;
}
main {
  background-color: #d7d7d7;
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #2e363a;
  font-weight: lighter !important;
}
h1 {
  font-size: 48px;
  margin: 0;
}

h2 {
  color: #2e363a;
  font-weight: lighter !important;
}

h6 {
  font-weight: bold;
  font-size: 14px;
  padding: 0px 0px 0px 10px;
}

ul {
  margin: 0;
  list-style-type: none;
}

p {
  padding: 5px;
  margin: 0px;
}

a {
  color: #333;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.exhibitions ul.bold {
  font-weight: bold;
}

.exhibitions ul.bold li {
  font-weight: normal;
  margin: 5px 0;
}
.exhibitions ul.bold li:before {
  content: '– ';
  text-indent: 5px;
}

.exhibitions ul {
  margin-bottom: 20px;
}

.quote {
  textalign: right;
  color: #2e363a;
}
.carousel .slide {
  background: transparent !important;
}
.carousel .slide.selected {
  cursor: pointer;
}
.carousel.carousel-slider .control-arrow {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.Collapsible__trigger {
  cursor: pointer;
  padding: 10px;
  background-color: #999;
  color: white;
  display: block;
}

.paintingHolderImageHolder {
  position: relative;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease-in-out;
}

.paintingHolderImageHolder:after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  box-shadow: 0px 10px 10px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}
.paintingHolderImageHolder img {
  margin-bottom: -4px;
}
/* Scale up the box */
.paintingHolderImageHolder img:hover {
  transform: scale(1.01, 1.01);
}
/* Scale up the box */
.paintingHolderImageHolder:hover {
  transform: scale(1.01, 1.01);
}

/* Fade in the pseudo-element with the bigger shadow */
.paintingHolderImageHolder:hover::after {
  opacity: 1;
}
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 400ms ease-in-out;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
